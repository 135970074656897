<template>
  <div class="login">
    <el-row :gutter="10" v-if="pcRequirement">
      <el-col :xs="20" :sm="12" :md="8" :lg="8" :xl="8">
        <div class="grid-content bg-purple">
          <div class="loginsun">
            <el-form
              :model="formList"
              :rules="rules"
              status-icon
              ref="ruleForm"
              label-width="60px"
              class="demo-ruleForm"
            >
              <h1 style="margin-bottom: 30px"> BI 系 统</h1>
              <el-form-item label="账号" prop="name">
                <el-input v-model="formList.name"></el-input>
              </el-form-item>
              <el-form-item label="密码" prop="pass">
                <el-input
                  v-model="formList.pass"
                  type="password"
                  autocomplete="off"
                ></el-input>
              </el-form-item>
              <el-form-item>
                <el-button type="primary" @click="submitForm">登录</el-button>
              </el-form-item>
            </el-form>
          </div>
        </div>
      </el-col>
    </el-row>

    <div class="mobileBox" v-if="!pcRequirement">
      <div class="header">BI系统</div>
      <van-form @submit="onSubmit">
        <van-field
          v-model="username"
          name="username"
          label="账号"
          placeholder="账号"
          :rules="[{ required: true, message: '请填写账号' }]"
        />
        <van-field
          v-model="password"
          type="password"
          name="password"
          label="密码"
          placeholder="密码"
          :rules="[{ required: true, message: '请填写密码' }]"
        />
        <div style="margin:40px;margin-top:1rem">
          <van-button round block type="info" native-type="submit"
            style="height:1rem"
            >
            <span style="font-size:.4rem">登录</span>
            </van-button
          >
        </div>
      </van-form>
    </div>
  </div>
</template>
<script>
// import axios from "axios"
import { loginfunction } from "@/api/login.js";
import { getLargeCategory } from "@/api/chart/index.js";
import { setSession } from "@/utils/methods";
import { tokenOrToken } from "@/api/bisystem/bisystem"
// import qs from "qs"
import jsCookie from "js-cookie";
export default {
  name: "Login",
  data() {
    return {
      formList: {
        name: "",
        pass: "",
      },
      username: "",
      password: "",
      rules: {
        name: [{ required: true, message: "账号不能为空", trigger: "blur" }],
        pass: [{ required: true, message: "密码不能为空", trigger: "blur" }],
      },
      pcRequirement: true,
    };
  },
  created() {
    this.init();
  },
  methods: {
    init() {
      this.pcRequirement = this.$pcEquipment;
    },
    // 登录
    submitForm() {
      this.$refs.ruleForm.validate((valid) => {
        // 正则验证通过
        if (valid) {
         this.$store.dispatch('Login',this.formList).then(res=>{
              this.$router.push({
                        name: "Home",
                      });
         }).catch(err=>{
          this.$message.error("登录失败");})
       

          // 浏览器本地保存相关数据
          // jsCookie.set('tokenfid', res.data.data.fid)
        } else {
          // 正则验证未通过
          this.$message({
            message: "清输入账号密码",
            type: "warning",
          });
          return false;
        }
      });
    },

    // 移动端登录
    onSubmit(form) {
      loginfunction(form).then((res) => {
        if (res.token) {
          jsCookie.set("token", res.token);
          jsCookie.set("userDetails", res.userDetails);
          this.$router.push({
            name: "Home",
          });
        } else {
          this.$message.error("登录失败");
        }
      });
    },
    getrouter() {
      getLargeCategory().thne((res) => {
        setSession("routersess", res);
      });
    },
  },
};
</script>
<style scoped lang="less">
@font-size-base: 75;
.login {
  width: 100%;
  height: 100%;
  background: url("../../assets/background.jpg");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.login > img {
  width: 100%;
  height: 100%;
}
.loginsun {
  width: 100%;
  height: 100%;
  /* margin-right: 15%; */
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: darkgrey 0px 0px 30px 5px;
  background: rgb(250, 248, 248);
}
.demo-ruleForm {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.demo-ruleForm > div {
  width: 70%;
  margin: 20px 0;
}
.el-button--primary {
  width: 80%;
}
.el-row {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.el-col {
  border-radius: 4px;
}
.bg-purple-dark {
  background: #99a9bf;
}
.bg-purple {
  background: #d3dce6;
}
.bg-purple-light {
  background: #e5e9f2;
}
.grid-content {
  border-radius: 4px;
  min-height: 36px;
  height: 380px;
}
.mobileBox {
  height: 8rem;
  width: 8rem;
  background: #faf8f8;
  box-shadow: darkgrey 0px 0px 30px 5px;
  border-radius: 0.266667rem;
  .header {
    font-size: 0.533333rem;
    height: 1.6rem;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  /deep/.van-form{
      font-size: .533333rem;
      height: 4rem;
  }
  /deep/.van-cell {
      height: 1.4rem;
      input{
          height: 1rem;
          font-size: .4rem;
      }
      .van-cell__title{
          font-size: .4rem;
          display: flex;
          justify-content: space-around;
          align-items: center;
      }
  }
}
</style>